<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

  <!-- Top navbar -->
  <mat-toolbar class="navbar" color="primary">

    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
      <h1>
        angular-material-template
      </h1>
    </a>

    <span class="navbar-spacer"></span>

    <button mat-icon-button [matMenuTriggerFor]="notificationMenu">
      <mat-icon matBadge="2" matBadgeColor="accent">notifications</mat-icon>
    </button>
    <mat-menu #notificationMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">

      <a mat-menu-item>
        <span>You have new tasks</span>
      </a>
      <a mat-menu-item>
        <span>You have a new message</span>
      </a>

    </mat-menu>



    <button mat-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
      <span fxShow fxHide.xs>
        {{userName}}
      </span>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">

      <a mat-menu-item [routerLink]="['/account/profile']">
        <mat-icon>person</mat-icon>
        <span>Account</span>
      </a>

      <a mat-menu-item [routerLink]="['/auth/login']">
        <mat-icon>exit_to_app</mat-icon>
        <span>Log out</span>
      </a>

    </mat-menu>

  </mat-toolbar>

  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">

      <mat-nav-list>
        <h3 mat-subheader>Home</h3>

        <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            dashboard
          </mat-icon>
          <p mat-line> Dashboard </p>
        </a>

        <a mat-list-item [routerLink]="['/customers']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            business
          </mat-icon>
          <p mat-line> Customers </p>
        </a>
        <a mat-list-item [routerLink]="['/users']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            people
          </mat-icon>
          <p mat-line> Users </p>
        </a>

        <a mat-list-item [routerLink]="['/account/profile']">
          <mat-icon mat-list-icon>person</mat-icon>
          <p mat-line> Account </p>
        </a>

        <a mat-list-item [routerLink]="['/auth/login']">
          <mat-icon mat-list-icon>exit_to_app</mat-icon>
          <p mat-line> Log out </p>
        </a>

        <mat-divider></mat-divider>

        <h3 mat-subheader>Resources</h3>

        <a mat-list-item [routerLink]="['/icons']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            toll
          </mat-icon>
          <p mat-line> Icons </p>
        </a>
        <a mat-list-item [routerLink]="['/typography']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            font_download
          </mat-icon>
          <p mat-line> Typography </p>
        </a>

        <a mat-list-item href="https://material.angular.io/components/categories" target="_blank">
          <mat-icon mat-list-icon>
            link
          </mat-icon>
          <p mat-line> Material Components </p>
        </a>

        <a id="push-bottom" mat-list-item [routerLink]="['/about']" routerLinkActive="active">
          <mat-icon mat-list-icon>
            info_outline
          </mat-icon>
          <p mat-line> About </p>
        </a>
      </mat-nav-list>

    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content">

      <div class="progress-bar-container">
        <mat-progress-bar color="accent" mode="indeterminate" *ngIf="spinnerService.visibility | async">
        </mat-progress-bar>
        <!-- <mat-spinner class="spinner" [diameter]="50" *ngIf="showSpinner"></mat-spinner> -->
      </div>


      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>